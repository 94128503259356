@import 'styles/layers.scss';

@layer uikit {
  .checkbox {
    position: relative;
    display: flex;
    user-select: none;
    margin: 0;
    cursor: pointer;
  
    &__control {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      border: 0;
      clip: rect(0 0 0 0);
      overflow: hidden;
  
      &:checked + .checkbox {
        &__checkmark {  
          &_invalid {
            border-color: var(--yellow) !important;
            background: var(--yellow) !important;
          }
  
          svg {
            stroke-dashoffset: 0;
          }
  
          &_disabled {
            opacity: 0.4;
          }
  
          &_semi {
            opacity: 0.4 !important;
          }
        }
  
        &__label {
          &_invalid {
            color: var(--yellow);
          }
        }
      }
    }
  
    &__checkmark {
      position: relative;
      display: block;
      width: 16px;
      min-width: 16px;
      height: 16px;
      margin-right: 6px;
      border-radius: 2px;
  
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        stroke-dashoffset: 35px;
        stroke-dasharray: 35px;
        transition: stroke-dashoffset .25s linear;
        color: transparent;
      }
  
      &_disabled {
        opacity: 0.4;
      }
    }
  
    &__label {
      font-size: 12px;
      font-weight: 300;
      line-height: 19px;
      user-select: none;
      color: inherit;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      
      &_disabled {
        opacity: 0.4;
      }
    }
  }
  
  .center {
    align-items: center;
  }
  
  .top {
    align-items: flex-start;
  }
  
  .disabled {
    cursor: default;
  }
  
  .notification {
    margin-top: 8px;
  }
  
  .checkbox {
    &__control {
      &:checked + .checkbox {
        &__checkmark {
          border: none;
          background-color: var(--white);
          svg {
            color: var(--black8);
          }
        }
      }
    }
    &__checkmark {
      border: 1px solid var(--grey7);
      &_invalid {
        border-color: var(--yellow);
      }
    }
    &__label {
      color: var(--white);
    }
  }
  
  .description {
    margin-bottom: 10px;
    font-size: 12px;
  }
}